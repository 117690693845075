import {FC, useState} from 'react'
import {SectorDTO} from '../../../models/DTOs/sectores/SectorDTO'
import {Nav} from 'react-bootstrap'
import {KTSVG} from '../KTSVG'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import ModalLabAndSector from './ModalLabAndSector'
import {LaboratorioSummaryDTO} from '../../../models/DTOs/laboratorios/LaboratorioSummaryDTO'

const SelectLabAndSector: FC = () => {
  const sectorActual: SectorDTO = useSelector<RootState>(
    ({auth}) => auth.sector,
    shallowEqual
  ) as SectorDTO
  const labActual: LaboratorioSummaryDTO = useSelector<RootState>(
    ({auth}) => auth.laboratorio,
    shallowEqual
  ) as LaboratorioSummaryDTO

  const [showDlg, setShowDlg] = useState(false)

  return (
    <>
      <div>
        <Nav.Link onClick={() => setShowDlg(true)} className='btnLabsAndSectores'>
          <table>
            <tbody>
              <tr>
                <td rowSpan={2}>
                  <span className='menu-icon mx-1'>
                    <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-1' />
                  </span>
                </td>
                <td>
                  <span>{labActual.Nombre}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{sectorActual.Nombre}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </Nav.Link>
      </div>

      {showDlg && <ModalLabAndSector onClose={() => setShowDlg(false)} />}
    </>
  )
}

export default SelectLabAndSector
