import {FC} from 'react'
import {KTSVG} from '../KTSVG'

const AlertWarning: FC = ({children}) => {
  return (
    <div className='alert alert-warning d-flex align-items-center'>
      <span className='svg-icon svg-icon-2hx svg-icon-warning me-4'>
        <KTSVG path='/media/icons/duotune/general/gen044.svg' />
      </span>
      {children}
    </div>
  )
}

const AlertDanger: FC = ({children}) => {
  return (
    <div className='alert alert-danger d-flex align-items-center'>
      <span className='svg-icon svg-icon-2hx svg-icon-danger me-4'>
        <KTSVG path='/media/icons/duotune/general/gen040.svg' />
      </span>
      {children}
    </div>
  )
}
const AlertInfo: FC = ({children}) => {
  return (
    <div className='alert alert-primary d-flex align-items-center'>
      <span className='svg-icon svg-icon-2hx svg-icon-primary me-4'>
        <KTSVG path='/media/icons/duotune/general/gen045.svg' />
      </span>
      {children}
    </div>
  )
}

const AlertSuccess: FC = ({children}) => {
  return (
    <div className='alert alert-success d-flex align-items-center'>
      <span className='svg-icon svg-icon-2hx svg-icon-success me-4'>
        <KTSVG path='/media/icons/duotune/general/gen043.svg' />
      </span>
      {children}
    </div>
  )
}

export {AlertDanger, AlertInfo, AlertSuccess, AlertWarning}
