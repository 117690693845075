import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

const getSectores = () => {
  return axios.get(ROUTES.SECTORES)
}

const getSectoresAsync = async (labId?: string) => {
  const {data} = await axios.get(ROUTES.SECTORES, {
    headers: {'X-CURRENT-LABORATORIO': labId},
  })

  return data
}

const useGetSectoresQuery = (labId?: string) => {
  return useQuery('useGetSectoresQuery', () => getSectoresAsync(labId))
}

export {useGetSectoresQuery, getSectores, getSectoresAsync}
