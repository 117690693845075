import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

const getLaboratoriosGrupo = async () => {
  const {data} = await axios.get(`${ROUTES.GRUPOS_LABORATORIOS}/laboratorios`)

  return data
}

const useGetLaboratoriosGrupoQuery = () => {
  return useQuery('useGetLaboratoriosGrupoQuery', () => getLaboratoriosGrupo())
}

export {getLaboratoriosGrupo, useGetLaboratoriosGrupoQuery}
