import axios from 'axios'
import ROUTES from '../../helpers/Routes'

interface Props {
  url: string
  titulo: string
}

const getPDF = async ({url, titulo}: Props) => {
  const {data} = await axios.get(ROUTES.PDF_OLD, {params: {url, titulo}})

  return data
}

export {getPDF}
