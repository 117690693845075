import {randomNumber, randomString} from '../../../helpers/RandomString'
import {SectorModel} from '../../../models/SectorModel'

const SECTORS_KEY = 'SectoresTableMock'

export class SectoresTableMock {
  public static table: Array<SectorModel> = [
    {
      Id: 1,
      Nombre: 'Sector 1',
      IsActivo: true,
      InstitucionId: 2,
      Usuarios: [1],
    },
  ]

  private static _table: Array<SectorModel> = []

  private static save() {
    localStorage.setItem(SECTORS_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const sectoresStorage = localStorage.getItem(SECTORS_KEY)
    this._table = sectoresStorage !== null ? JSON.parse(sectoresStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static createSector(nombre: string, institucionId: number) {
    const sector: SectorModel = {
      Id: randomNumber(10),
      Nombre: nombre,
      InstitucionId: institucionId,
      IsActivo: true,
    }
    this.read()
    this._table.push(sector)
    this.save()
  }

  static updateSector(sectorId: number, nombre: string) {
    this.read()
    const sector = this._table.find((x) => x.Id === sectorId)
    if (!sector) return

    sector.Nombre = nombre

    this.save()
  }

  static updateStatus(sectorId: number) {
    this.read()
    const sector = this._table.find((x) => x.Id === sectorId)
    if (!sector) return

    sector.IsActivo = !sector.IsActivo

    this.save()
  }
}
