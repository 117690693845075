import {randomNumber} from '../../../helpers/RandomString'
import {EquipoModel} from '../../../models/EquipoModel'

const KEY = 'EquiposTableMock'

export class EquiposTableMock {
  public static table: Array<EquipoModel> = [
    {
      Id: 1,
      Plataforma: {Id: 1, Descripcion: 'SuperPlat'},
      Descripcion: 'Equipo 1',
      NumSerie: '1111',
      IsActivo: true,
      InstitucionId: 2,
    },
  ]

  private static _table: Array<EquipoModel> = []

  private static read() {
    const storage = localStorage.getItem(KEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  private static save() {
    localStorage.setItem(KEY, JSON.stringify(this._table))
  }

  static createEquipo(
    equipo: {
      Plataforma: {
        Id: number
        Descripcion: string
      }
      Descripcion: string
      NumSerie: string
    },
    institucionId: number
  ) {
    this.read()
    this._table.push({
      Id: randomNumber(5),
      Descripcion: equipo.Descripcion,
      Plataforma: equipo.Plataforma,
      NumSerie: equipo.NumSerie,
      IsActivo: true,
      InstitucionId: institucionId,
    })
    this.save()
  }

  static updateEquipo(equipoId: number, equipo: {Descripcion: string; NumSerie: string}) {
    this.read()
    const equipoActual = this.getTable().find((a) => a.Id === equipoId)
    if (!equipoActual) return

    equipoActual.NumSerie = equipo.NumSerie
    equipoActual.Descripcion = equipo.Descripcion

    this.save()
  }

  static updateStatus(id: number) {
    this.read()
    const equipo = this.getTable().find((a) => a.Id === id)
    if (!equipo) return

    equipo.IsActivo = !equipo.IsActivo

    this.save()
  }
}
