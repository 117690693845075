import {FC, useState} from 'react'
import {SectorDTO} from '../../../models/DTOs/sectores/SectorDTO'
import {Nav} from 'react-bootstrap'
import {KTSVG} from '../KTSVG'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import ModalSectores from './ModalSectores'

const SelectSector: FC = () => {
  const sectorActual: SectorDTO = useSelector<RootState>(
    ({auth}) => auth.sector,
    shallowEqual
  ) as SectorDTO

  const [showDlg, setShowDlg] = useState(false)

  return (
    <>
      <div>
        <Nav.Link onClick={() => setShowDlg(true)} className='btnSectores'>
          <span className='menu-icon mx-1'>
            <KTSVG path='/media/icons/duotune/general/gen018.svg' className='svg-icon-1' />
          </span>
          {sectorActual.Nombre}
        </Nav.Link>
      </div>

      {showDlg && <ModalSectores onClose={() => setShowDlg(false)} />}
    </>
  )
}

export default SelectSector
