import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../helpers'

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: process.env.REACT_APP_NAME})}</PageTitle>

      <div className='text-center'>
        <img
          className='icon h-200px w-200px mb-10'
          src={toAbsoluteUrl('/media/logosG/logo_gm_sce.png')}
        />
        <h1 className='text-gray-700'>{`GMonitor SCE`}</h1>
      </div>
    </>
  )
}

export {DashboardWrapper}
