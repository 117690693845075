import {API_OLD_URL, API_URL} from './API_URLHelper'

const ROUTES = {
  USUARIOS: `${API_URL}/usuarios`,
  ACCOUNT: `${API_URL}/account`,
  PAISES: `${API_URL.replace('/admin', '')}/paises`,
  TICKETS: `${API_URL}/support/tickets`,
  INSTITUCIONES: `${API_URL}/laboratorios`,
  AUTH: `${API_URL}/auth`,
  AUTH_PASSWORD: `${API_URL}/auth/password`,
  APLICACIONES: `${API_URL}/aplicaciones`,
  BATCH_USUARIOS: `${API_URL}/batch/usuarios`,
  SECTORES: `${API_URL}/sectores`,
  USUARIOS_SECTORES: `${API_URL}/usuarios/sectores`,
  REACTIVOS: `${API_URL}/reactivos`,
  PRINCIPIOS_MEDIDA: `${API_URL}/principios-medida`,
  PLATAFORMAS: `${API_URL}/plataformas`,
  ANALITOS: `${API_URL}/analitos`,
  UNIDADES: `${API_URL}/unidades`,
  MATRICES: `${API_URL}/matrices`,
  MATERIALES_CONTROL: `${API_URL}/materiales-control`,
  MATERIALES_CONTROL_OLD: `${API_OLD_URL}/materialesControl`,
  MATERIALES_CONTROL_LOTES: `${API_URL}/materiales-control/:id/lotes-control`,
  EQUIPOS: `${API_URL}/equipos`,
  SECTORES_PROCEDIMIENTOS_MEDIDA: `${API_URL}/sectores/:id/procedimientos-medida`,
  PROCEDIMIENTOS_MEDIDA: `${API_URL}/procedimientos-medida`,
  EDAS: `${API_URL}/procedimientos-medida/edas`,
  EDAS_FUENTES: `${API_URL}/procedimientos-medida/edas/fuentes`,
  REGLAS_CONTROL: `${API_URL}/cc/reglasControl`,
  CC_RESULTADOS: `${API_URL}/cc/resultados`,
  SCI_RESULTADOS: `${API_URL}/sci/resultados`,
  SCI_RESULTADOS_OLD: `${API_OLD_URL}/sci/resultados`,
  SCI_REPORTES: `${API_URL}/sci/reportes`,
  SCI_REPORTES_OLD: `${API_OLD_URL}/sci/reportes`,
  PDF: `${API_URL}/pdf`,
  LABORATORIO: `${API_URL}/laboratorio`,
  APLICACIONES_OLD: `${API_OLD_URL}/aplicaciones`,
  MATERIALES_CONTROL_LOTES_OLD: `${API_OLD_URL}/materialesControl/:id/lotes`,
  REGLAS_CONTROL_OLD: `${API_OLD_URL}/cc/reglasControl`,
  CC_RESULTADOS_OLD: `${API_OLD_URL}/cc/resultados`,
  PDF_OLD: `${API_OLD_URL}/pdf`,
  INCIDENTES: `${API_URL}/incidentes`,
  INCIDENTES_OLD: `${API_OLD_URL}/incidentes`,
  SCE: `${API_URL}/sce`,
  SCE_REPORTES: `${API_URL}/sce/reportes`,
  SCE_OLD: `${API_OLD_URL}/sce`,
  SCE_REPORTES_OLD: `${API_OLD_URL}/sce/reportes`,
  GRUPOS_LABORATORIOS: `${API_URL}/grupos-laboratorios`,
}

export default ROUTES
