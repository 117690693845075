/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../app/helpers'
import {useLang, setLanguage} from '../../../../i18n/Metronici18n'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../../app/models/UserModel'
import {RootState} from '../../../../../setup'
import {useUpdateUsuarioIdiomaMutation} from '../../../../../app/modules/account/update-usuario-idioma'

const languages = [
  // {
  //   lang: 'en',
  //   name: 'English',
  //   flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  // },
  {
    lang: 'es',
    name: 'Español',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
]

const Languages: FC = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {mutate: updateUserLang} = useUpdateUsuarioIdiomaMutation()

  const langChangeHandler = (l: {lang: string; name: string; flag: string}) => {
    if (l.lang !== lang) updateUserLang({usuarioId: user.Id, idioma: l.name.toLowerCase()})
    setLanguage(l.lang)
  }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'LANGUAGES'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='GMigliarino'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div className='menu-item px-3' key={l.lang} onClick={() => langChangeHandler(l)}>
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='GM' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
