import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

interface Props {
  id: string
}

const findStorePdfData = async ({id}: Props) => {
  const {data} = await axios.get(`${ROUTES.PDF_OLD}/temp-data/${id}`)

  return data
}

const useFindStorePdfDataQuery = (input: Props) => {
  return useQuery('useFindStorePdfDataQuery', () => findStorePdfData(input))
}

export {findStorePdfData, useFindStorePdfDataQuery}
