import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  usuarioId: number
  idioma: string
}

const updateUsuarioIdioma = async ({idioma, usuarioId}: Props) => {
  return await axios.put(`${ROUTES.ACCOUNT}/${usuarioId}/idioma`, null, {params: idioma})
}

const useUpdateUsuarioIdiomaMutation = () => {
  return useMutation('useUpdateUsuarioIdiomaMutation', (input: Props) => updateUsuarioIdioma(input))
}

export {useUpdateUsuarioIdiomaMutation}
