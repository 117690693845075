import React, {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {RolUsuario} from '../models/DTOs/common/types'
import {Logout} from '../pages/auth'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const AccountPage = lazy(() => import('../pages/account/AccountPage'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const Muestras = lazy(() => import('../pages/muestras/Muestras'))
  const Desempenio = lazy(
    () => import('../pages/reportes/evaluacion-procedimiento-medida/Desempenio')
  )
  const EstimacionSesgo = lazy(
    () => import('../pages/reportes/evaluacion-procedimiento-medida/EstimacionSesgo')
  )
  const EDA = lazy(() => import('../pages/reportes/evaluacion-procedimiento-medida/EDA'))
  const MetasMU = lazy(() => import('../pages/reportes/evaluacion-procedimiento-medida/MetasMU'))
  const SesgoRegresion = lazy(
    () => import('../pages/reportes/evaluacion-procedimiento-medida/SesgoRegresion')
  )
  const DesempeñoMuestra = lazy(
    () => import('../pages/reportes/evaluacion-laboratorio/DesempeñoMuestra')
  )

  const isAdmin = user.Rol.Name === RolUsuario.AdministradorLaboratorio

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/account' component={AccountPage} />
        <Route path='/support/tickets' component={Tickets} />
        <Route exact path='/samples' component={Muestras} />
        <Route exact path='/reports/performance' component={Desempenio} />
        <Route exact path='/reports/bias-estimation' component={EstimacionSesgo} />
        <Route exact path='/reports/eda' component={EDA} />
        <Route exact path='/reports/mu-goals' component={MetasMU} />
        <Route exact path='/reports/regression-bias' component={SesgoRegresion} />
        <Route exact path='/reports/performance-sample' component={DesempeñoMuestra} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
