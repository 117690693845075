import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

interface Props {
  token: string
}

const getOTAContent = async ({token}: Props) => {
  const {data} = await axios.get(`${ROUTES.AUTH}/ota/${token}`)

  return data
}

const useGetOTAContentQuery = (input: Props) => {
  return useQuery('useGetOTAContentQuery', () => getOTAContent(input))
}

export {getOTAContent, useGetOTAContentQuery}
