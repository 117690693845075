import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

const getLaboratorio = () => {
  return axios.get(ROUTES.LABORATORIO)
}

const getLaboratorioAsync = async () => {
  const {data} = await axios.get(ROUTES.LABORATORIO)

  return data
}

const useGetLaboratorioQuery = () => {
  return useQuery('useGetLaboratorioQuery', () => getLaboratorioAsync())
}

export {useGetLaboratorioQuery, getLaboratorio, getLaboratorioAsync}
