import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

const getUsuarioProfile = () => {
  return axios.get(`${ROUTES.USUARIOS}/profile`)
}

const getUsuarioProfileAsync = async () => {
  const {data} = await axios.get(`${ROUTES.USUARIOS}/profile`)

  return data
}

const useGetUsuarioProfileQuery = () => {
  return useQuery('useGetUsuarioProfileQuery', () => getUsuarioProfileAsync())
}

export {getUsuarioProfileAsync, useGetUsuarioProfileQuery, getUsuarioProfile}
