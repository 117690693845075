import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {AplicacionDTO} from '../../../models/DTOs/aplicaciones/AplicacionDTO'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {showError} from '../../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../../helpers/error-message-builder'
import {useCreateOTATokenMutation} from '../../../modules/auth/create-ota-token'
import {IAuthState} from '../../../pages/auth'

type Props = {
  aplicaciones: AplicacionDTO[]
}

const ApplicationsMenu: FC<Props> = ({aplicaciones}) => {
  const intl = useIntl()
  const auth: IAuthState = useSelector<RootState>(({auth}) => auth, shallowEqual) as IAuthState

  const [selectedApp, setSelectedApp] = useState<AplicacionDTO>()

  const {
    isError: isOTAError,
    isSuccess: isOTASuccess,
    data: OTAData,
    error: OTAError,
    mutate: createOTA,
  } = useCreateOTATokenMutation()

  useEffect(() => {
    if (!isOTAError && !isOTASuccess) return

    if (isOTASuccess && OTAData && selectedApp) {
      window.location.href = `${selectedApp.Link}?ota=${OTAData}`
    } else if (isOTAError) {
      showError(
        intl.formatMessage({id: 'ERROR'}),
        errorMessageBuilder(OTAError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isOTASuccess, isOTAError, OTAData, selectedApp])

  const handleCreateOTA = (aplicacion: AplicacionDTO) => {
    setSelectedApp(aplicacion)
    createOTA({
      content: JSON.stringify({
        AccessToken: auth.accessToken,
        RefreshToken: auth.refreshToken,
        Laboratorio: auth.laboratorio,
        Sector: auth.sector,
      }),
    })
  }

  return (
    <div
      className='menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown p-0 w-100 w-lg-600px'
      data-kt-menu='true'
    >
      <div
        className='menu-state-bg menu-extended overflow-hidden overflow-lg-visible'
        data-kt-menu-dismiss='true'
      >
        <div className='row mb-3 mb-lg-0 py-3 px-3 py-lg-6 px-lg-6'>
          {aplicaciones.map((aplicacion) => {
            return (
              <div className='col-lg-6 mb-3' key={`aplicaciones_${aplicacion.Id}`}>
                <div className='menu-item p-0 m-0 h-60px'>
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() => handleCreateOTA(aplicacion)}
                    className='menu-link'
                  >
                    <span className='menu-custom-icon d-flex flex-center flex-shrink-0 rounded w-40px h-40px me-3'>
                      <img
                        className='icon h-40px w-40px'
                        alt=''
                        src={toAbsoluteUrl(aplicacion.Icono)}
                      />
                    </span>
                    <span className='d-flex flex-column'>
                      <span className='fs-6 fw-bold text-gray-800'>{aplicacion.Nombre}</span>
                      <span className='fs-7 fw-semibold text-muted'>
                        {intl.formatMessage({id: aplicacion.Descripcion})}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ApplicationsMenu
