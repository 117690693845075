import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {RolUsuario} from '../../../../app/models/DTOs/common/types'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {user.Rol.Name === RolUsuario.AdministradorLaboratorio && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'HEADER.SAMPLES'})}
              </span>
            </div>
          </div>

          <AsideMenuItem
            id='muestras'
            to='/samples'
            icon='/media/icons/duotune/text/txt001.svg'
            title={intl.formatMessage({id: 'SETTINGS.ADMIN'})}
            fontIcon='bi-app-indicator'
          />

          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'MENU.REPORT'})}
              </span>
            </div>
          </div>

          <AsideMenuItemWithSub
            title={intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE_EVALUATION'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/graphs/gra001.svg'
            to='/reportes'
          >
            <AsideMenuItem
              id='desempenio'
              to='/reports/performance'
              hasBullet
              title={intl.formatMessage({id: 'HEADER.PERFORMANCE'})}
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              id='estimacionSesgo'
              to='/reports/bias-estimation'
              hasBullet
              title={intl.formatMessage({id: 'HEADER.BIAS_ESTIMATION'})}
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              id='eda'
              to='/reports/eda'
              hasBullet
              title={intl.formatMessage({id: 'MENU.EDA'})}
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              id='metasMU'
              to='/reports/mu-goals'
              hasBullet
              title={intl.formatMessage({id: 'HEADER.MU_GOALS'})}
              fontIcon='bi-app-indicator'
            />
            <AsideMenuItem
              id='sesgoRegresion'
              to='/reports/regression-bias'
              hasBullet
              title={intl.formatMessage({id: 'HEADER.REGRESSION_BIAS'})}
              fontIcon='bi-app-indicator'
            />
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub
            title={intl.formatMessage({id: 'HEADER.LABORATORY_EVALUATION'})}
            fontIcon='bi-app-indicator'
            icon='/media/icons/duotune/graphs/gra001.svg'
            to='/evalLaboratorio'
          >
            <AsideMenuItem
              id='desempeñoMuestra'
              to='/reports/performance-sample'
              title={intl.formatMessage({id: 'HEADER.PERFORMANCE_SAMPLE'})}
              hasBullet
              fontIcon='bi-app-indicator'
            />
          </AsideMenuItemWithSub>
        </>
      )}
    </>
  )
}
