import {randomNumber, randomString} from '../../../helpers/RandomString'
import {CreateUsuarioDTO} from '../../../models/DTOs/usuario/CreateUsuarioDTO'
import {UserModel} from '../../../models/UserModel'
import {UsuarioDTO} from '../../../models/DTOs/usuario/UsuarioDTO'
import {ResponseDTO} from '../../../models/DTOs/common/ResponseDTO'
import {CreateUsuarioBatchDTO} from '../../../models/DTOs/usuario/CreateUsuarioBatchDTO'
import {RolUsuario} from '../../../models/DTOs/common/types'

const USUARIO_KEY = 'UsuariosTableMock'

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      Id: 1,
      Email: 'aqcsuiteclient@gmigliarino.com',
      Rol: {
        Id: 3,
        Name: 'admin',
      }, // Administrator
      Nombre: 'LICON CLIENT',
      IsActivo: true,
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      InstitucionId: 1,
      IsResponsableFromGrupoLaboratorios: true,
    },
  ]

  private static _table: Array<UserModel> = []

  private static save() {
    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const usuariosStorage = localStorage.getItem(USUARIO_KEY)
    this._table = usuariosStorage !== null ? JSON.parse(usuariosStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearUsuario(newUserDTO: CreateUsuarioDTO): UserModel {
    const newUser: UserModel = {
      Id: randomNumber(5),
      IsActivo: true,
      Email: newUserDTO.Email,
      Nombre: newUserDTO.Nombre,
      Rol: {
        Id:
          newUserDTO.Rol === 'administrador'
            ? 5
            : newUserDTO.Rol === 'administrador-laboratorio'
            ? 7
            : 6,
        Name: newUserDTO.Rol,
      },
      Auth: {
        AccessToken: 'access-token-' + randomString(32),
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
      InstitucionId: 1,
      IsResponsableFromGrupoLaboratorios: true,
    }
    this.read()
    this._table.push(newUser)
    this.save()
    return {...newUser}
  }

  static updateState(usuarioId: number) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel
    if (usuario == null) return

    usuario.IsActivo = !usuario.IsActivo

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateUser(
    usuarioId: number,
    user: {
      Id: number
      Rol: number
      Nombre: string
      Idioma: number
    }
  ) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel
    if (!usuario) return

    usuario.Nombre = user.Nombre
    usuario.Rol = {
      Id: user.Rol,
      Name:
        user.Rol === 5
          ? RolUsuario.Administrador
          : user.Rol === 6
          ? RolUsuario.ResponsableLaboratorio
          : RolUsuario.AdministradorLaboratorio,
    }
    //usuario.Idioma

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateEmail(usuarioId: number, email: string) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel
    if (!usuario) return

    usuario.Email = email

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateName(usuarioId: number, name: string) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel
    if (!usuario) return

    usuario.Nombre = name

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static crearUsuarios(institucion: string, usuarios: CreateUsuarioBatchDTO[]) {
    let responses: ResponseDTO[] = []

    this.read()
    usuarios.forEach((usuario) => {
      const isSuccess = usuario.Nombre !== 'Usuario malo'

      responses.push({
        IsSuccess: isSuccess,
        RequestId: usuario.RequestId,
        Errors: [],
      })

      if (isSuccess) {
        this._table.push({
          Id: randomNumber(5),
          IsActivo: true,
          Email: usuario.Email,
          Nombre: usuario.Nombre,
          Rol: {
            Id: usuario.RolId,
            Name:
              usuario.RolId === 5
                ? RolUsuario.Administrador
                : usuario.RolId === 6
                ? RolUsuario.ResponsableLaboratorio
                : RolUsuario.AdministradorLaboratorio,
          },
          Auth: {
            AccessToken: 'access-token-' + randomString(32),
            RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
          },
          InstitucionId: 1,
          IsResponsableFromGrupoLaboratorios: true,
        })
      }
    })
    this.save()

    return responses
  }
}
