import axios from 'axios'
import {CreateTicketDTO} from '../../models/DTOs/ticket/CreateTicketDTO'
import ROUTES from '../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  ticket: CreateTicketDTO
}

const createTicket = async ({ticket}: Props) => {
  return await axios.post(ROUTES.TICKETS, ticket)
}

const useCreateTicketMutation = () => {
  return useMutation('useCreateTicketMutation', (input: Props) => createTicket(input))
}

export {useCreateTicketMutation}
