import {FC, RefObject, useEffect, useRef, useState} from 'react'
import {EvalProcMedidaDesempenioDTO} from '../../../models/DTOs/SCE/reportes/EvalProcMedidaDesempenioDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact, {HighchartsReactRefObject} from 'highcharts-react-official'
more(Highcharts)

interface Props {
  reporte: EvalProcMedidaDesempenioDTO
}

const GraficoZscorePa: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()
  const chartRef = useRef<HighchartsReactRefObject>(null)

  const GetPlotLine = (value: number): Highcharts.YAxisPlotLinesOptions => {
    const result: Highcharts.YAxisPlotLinesOptions = {
      value: value,
      width: 1,
      color: '#525E64',
      dashStyle: 'Dash',
      zIndex: 2,
    }

    return result
  }

  const GetScatterSerie = (
    reporte: EvalProcMedidaDesempenioDTO,
    fillColor: string,
    lineColor: string
  ): Highcharts.SeriesOptionsType => {
    return {
      type: 'scatter',
      data: reporte.Resultados.map((resultado) => {
        const valorX = +resultado.Zscore.Value.toFixed(resultado.Zscore.Precision)
        const valorY = +resultado.Pa.Value.toFixed(resultado.Pa.Precision)

        return {
          x: valorX,
          y: valorY,
          marker: {
            symbol: 'circle',
            radius: 4,
          },
          nombre: `${resultado.Ciclo} / ${resultado.Encuesta} / ${resultado.Muestra}`,
        }
      }),
      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor || '#E86A04',
        fillColor: fillColor || '#FFF',
        lineWidth: 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  const DrawRectangleByX = (
    chart: Highcharts.Chart,
    xStart: number,
    xEnd: number,
    yStart: number,
    yEnd: number,
    color: string,
    zIndex: number
  ) => {
    const xPx = chart.xAxis[0].toPixels(xStart, false)
    const yPx = chart.yAxis[0].toPixels(yStart, false)

    const width = chart.xAxis[0].toPixels(xEnd, false) - chart.xAxis[0].toPixels(xStart, false)
    const height = chart.yAxis[0].toPixels(yEnd, false) - chart.yAxis[0].toPixels(yStart, false)

    chart.renderer
      .rect(xPx, yPx, width, height)
      .attr({
        fill: color,
        zIndex: zIndex,
      })
      .add()
  }

  useEffect(() => {
    const info = {
      title: 'Zscore vs %P<sub>A</sub>',
      xAxisTitle: 'Zscore',
      yAxisTitle: '%P<sub>A</sub>',
      xAxisMinValue: -4,
      xAxisMaxValue: 4,
      xAxisTickInterval: 1,
      yAxisMinValue: -125,
      yAxisMaxValue: 125,
      yAxisTickInterval: 25,
    }

    const series: Highcharts.SeriesOptionsType[] = []
    const plotLinesX: Highcharts.XAxisPlotLinesOptions[] = []
    const plotLinesY: Highcharts.YAxisPlotLinesOptions[] = []

    series.push(GetScatterSerie(reporte, '#FFF', '#000'))

    plotLinesX.push(GetPlotLine(0))
    plotLinesY.push(GetPlotLine(0))

    setOptions({
      title: {
        text: info.title,
        useHTML: true,
      },
      xAxis: {
        title: {
          text: info.xAxisTitle,
        },
        min: info.xAxisMinValue,
        max: info.xAxisMaxValue,
        tickInterval: info.xAxisTickInterval,
        lineWidth: 0,
        gridLineWidth: 0,
        endOnTick: true,
        plotLines: plotLinesX,
      },
      yAxis: {
        title: {
          text: info.yAxisTitle,
          useHTML: true,
        },
        min: info.yAxisMinValue,
        max: info.yAxisMaxValue,
        tickInterval: info.yAxisTickInterval,
        lineWidth: 0,
        gridLineWidth: 0,
        endOnTick: true,
        plotLines: plotLinesY,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '[{point.x} ; {point.y}]',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  useEffect(() => {
    if (!chartRef.current?.chart) return

    DrawRectangleByX(chartRef.current.chart, -4, 4, 125, -125, '#D91E18', 1)
    DrawRectangleByX(chartRef.current.chart, -3, 3, 100, -100, '#F7CA18', 1)
    DrawRectangleByX(chartRef.current.chart, -2, 2, 75, -75, '#36D7B7', 1)
    DrawRectangleByX(chartRef.current.chart, -1, 1, 50, -50, '#26C281', 1)
  }, [chartRef.current])

  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
}

export default GraficoZscorePa
