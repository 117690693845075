import {FC, useEffect, useState} from 'react'
import {EvalProcMedidaDesempenioDTO} from '../../../models/DTOs/SCE/reportes/EvalProcMedidaDesempenioDTO'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
more(Highcharts)

interface Props {
  reporte: EvalProcMedidaDesempenioDTO
}

const GraficoEncuestaDesvPercent: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  useEffect(() => {
    const maxETa = Math.max.apply(
      null,
      reporte.Resultados.map((resultado) => Math.abs(resultado.ETaAplicado.Value))
    )
    const yAxisMaxAbsValue = Math.ceil(maxETa * 1.2)

    const info = {
      title: 'Encuesta vs %Desvío',
      xAxisTitle: 'Encuesta',
      yAxisTitle: '%Desvío',
      yAxisMinValue: -yAxisMaxAbsValue,
      yAxisMaxValue: yAxisMaxAbsValue,
      yAxisTickInterval: Math.ceil(yAxisMaxAbsValue / 8),
    }

    const categories: string[] = []
    const seriesColumns: (
      | Highcharts.PointOptionsObject
      | [string | number, number]
      | [string | number, number, number]
    )[] = []
    const seriesETa: (
      | Highcharts.PointOptionsObject
      | [string | number, number]
      | [string | number, number, number]
    )[] = []

    for (const resultado of reporte.Resultados) {
      const desvPercent = +resultado.DesvPercent.Value.toFixed(resultado.DesvPercent.Precision)
      const etaAplicado = +resultado.ETaAplicado.Value.toFixed(resultado.ETaAplicado.Precision)

      categories.push(`${resultado.Ciclo} / ${resultado.Encuesta} / ${resultado.Muestra}`)
      seriesColumns.push({y: desvPercent, color: '#0F0'})
      seriesETa.push([-etaAplicado, etaAplicado])
    }

    setOptions({
      title: {
        text: info.title,
      },
      xAxis: {
        title: {
          text: info.xAxisTitle,
        },
        categories: categories,
        labels: {
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: info.yAxisTitle,
        },
        min: info.yAxisMinValue,
        max: info.yAxisMaxValue,
        tickInterval: info.yAxisTickInterval,
        lineWidth: 1,
        gridLineWidth: 1,
      },
      plotOptions: {
        series: {
          animation: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
              select: {
                enabled: false,
              },
            },
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: '%ETa',
          type: 'errorbar',
          data: seriesETa,
          color: '#26C281',
        },
        {
          name: '%Desvio',
          type: 'scatter',
          data: seriesColumns,
          marker: {
            enabled: true,
            symbol: 'circle',
            lineColor: '#000',
            fillColor: '#FFF',
            lineWidth: 1,
            states: {
              hover: {
                enabled: false,
              },
              select: {
                enabled: false,
              },
            },
          },
        },
      ],
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoEncuestaDesvPercent
