import {FC} from 'react'
import {EvalProcMedidaDesempenioDTO} from '../../models/DTOs/SCE/reportes/EvalProcMedidaDesempenioDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import TablaEvalProcMedidaDesempenio from '../../components/reportes/eval-proc-medida-desempenio/TablaEvalProcMedidaDesempenio'
import GraficoEncuestaDesvPercent from '../../components/reportes/graficos/GraficoEncuestaDesvPercent'
import GraficoEncuestaPa from '../../components/reportes/graficos/GraficoEncuestaPa'
import GraficoEncuestaZscore from '../../components/reportes/graficos/GraficoEncuestaZscore'
import GraficoValorAsignadoPa from '../../components/reportes/graficos/GraficoValorAsignadoPa'
import GraficoValorAsignadoZscore from '../../components/reportes/graficos/GraficoValorAsignadoZscore'
import GraficoZscorePa from '../../components/reportes/graficos/GraficoZscorePa'

const ReporteEvalProcDesempenio: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: EvalProcMedidaDesempenioDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  const handleModeloESaEAa = (modelo: string) => {
    return modelo === 'presupuesto-eta' ? 'Presupuesto de ETa' : 'Especificaciones de desempeño'
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='row'>
          <label className='col-2 form-label'>{intl.formatMessage({id: 'HEADER.PROGRAM'})}</label>
          <label className='col-8 form-label'>
            {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
          </label>
          <label className='col-2 form-label'>{intl.formatMessage({id: 'MENU.UNITS'})}</label>
        </div>
        <div className='row mb-8'>
          <span className='col-2 fw-bolder'>{reporte.Programa.Name}</span>
          <span className='col-8 fw-bolder'>{`${reporte.ProcedimientoMedida.Equipo.Descripcion} / ${reporte.ProcedimientoMedida.Equipo.Plataforma} | ${reporte.ProcedimientoMedida.Mensurando.Analito} / ${reporte.ProcedimientoMedida.Mensurando.Matriz} / ${reporte.ProcedimientoMedida.Mensurando.Unidades} | ${reporte.ProcedimientoMedida.Metodo.Reactivo} / ${reporte.ProcedimientoMedida.Metodo.PrincipioMedida}`}</span>
          <span className='col-2 fw-bolder'>{reporte.ProcedimientoMedida.Mensurando.Unidades}</span>
        </div>
        <div className='row'>
          <label className='col-2 form-label'>
            {`${intl.formatMessage({id: 'HEADER.SOURCE'})} ETa`}
          </label>
          <label className='col-2 form-label'>ETa</label>
          <label className='col-4 form-label'>
            {`${intl.formatMessage({id: 'HEADER.MODEL'})} ESa - EAa`}
          </label>
          <label className='col-2 form-label'>ESa</label>
          <label className='col-2 form-label'>EAa</label>
        </div>
        <div className='row mb-15'>
          <span className='col-2 fw-bolder'>
            {reporte.Resultados[0].EspDesempAnalitico.FuenteETa.Name}
          </span>
          <span className='col-2 fw-bolder'>
            {`${reporte.Resultados[0].EspDesempAnalitico.ETa.Percent.Value.toFixed(
              reporte.Resultados[0].EspDesempAnalitico.ETa.Percent.Precision
            )} %`}
          </span>
          <span className='col-4 fw-bolder'>
            {handleModeloESaEAa(reporte.Resultados[0].EspDesempAnalitico.ModeloESaEAa.Name)}
          </span>
          <span className='col-2 fw-bolder'>
            {`${reporte.Resultados[0].EspDesempAnalitico.ESa.Value.toFixed(
              reporte.Resultados[0].EspDesempAnalitico.ESa.Precision
            )} %`}
          </span>
          <span className='col-2 fw-bolder'>
            {`${reporte.Resultados[0].EspDesempAnalitico.EAa.Value.toFixed(
              reporte.Resultados[0].EspDesempAnalitico.EAa.Precision
            )} %`}
          </span>
        </div>
        <div className='mb-15'>
          <TablaEvalProcMedidaDesempenio reporte={reporte} />
        </div>
        <div className='row mb-15'>
          <div className='col-6'>
            <GraficoEncuestaZscore reporte={reporte} />
          </div>
          <div className='col-6'>
            <GraficoValorAsignadoZscore reporte={reporte} />
          </div>
        </div>
        <div className='mb-15'>
          <GraficoEncuestaDesvPercent reporte={reporte} />
        </div>
        <div className='row mb-15'>
          <div className='col-6'>
            <GraficoEncuestaPa reporte={reporte} />
          </div>
          <div className='col-6'>
            <GraficoValorAsignadoPa reporte={reporte} />
          </div>
        </div>
        <div></div>
        <div className='d-flex justify-content-center'>
          <div className='col-6'>
            <GraficoZscorePa reporte={reporte} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReporteEvalProcDesempenio
