import {useState, useEffect} from 'react'
import {findStorePdfData} from '../modules/pdf/find-store-pdf-data'
import {getPDF} from '../modules/pdf/get-pdf'
import {storePdfData} from '../modules/pdf/store-pdf-data'

export const encodeData = (data: any) => btoa(encodeURI(JSON.stringify(data)))

export const decodeData = (data: string) => JSON.parse(decodeURI(atob(data)))

export const getDataFromUrl = async () => {
  let data
  try {
    const urlSearch = new URLSearchParams(window.location.search)
    const dataBase64 = urlSearch.get('data')

    if (!dataBase64) return null

    data = decodeData(dataBase64)
  } catch (e) {
    console.error(e)
    return null
  }

  if (!data) return null

  if (data.source != 'store') return data

  try {
    const result = await findStorePdfData({id: data.payload_identifier})
    return JSON.parse(result)
  } catch {
    console.error('failed fetch pdf data')
    return null
  }
}

export const saveDataAsPdf = async (
  type: string,
  token: string,
  lang: string,
  data: any,
  titulo: string
) => {
  let storedDataIdentifier
  try {
    const result = await storePdfData({pdfData: data})
    storedDataIdentifier = result
    if (!storedDataIdentifier) throw new Error()
  } catch {
    console.error('failed to store pdf data')
    return
  }

  const storedData = {
    source: 'store',
    payload_identifier: storedDataIdentifier,
  }

  const storedDataBase64 = encodeData(storedData)
  const url = `${window.location.origin}/external?action=REPORTE_PDF&type=${type}&token=${token}&lang=${lang}`

  console.log(`${url}&data=${storedDataBase64}`)

  const result = await getPDF({url: `${url}&data=${storedDataBase64}`, titulo})

  const byteCharacters = atob(result)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const urlPdf = window.URL.createObjectURL(new Blob([byteArray]))
  const link = document.createElement('a')
  link.href = urlPdf
  link.setAttribute('download', 'Report.pdf')
  document.body.appendChild(link)
  link.click()
}

export const useInitPdf = () => {
  const [dataDecoded, setDataDecoded] = useState<any>()
  const [hasError, setHasError] = useState<boolean>(false)

  const init = async () => {
    try {
      const result = await getDataFromUrl()
      if (!result) throw new Error('error')

      setDataDecoded(result)
    } catch (e) {
      console.error(e)
      setHasError(true)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return {dataDecoded, hasError}
}
