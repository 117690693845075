import {useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useSendAuthenticationCodeMutation} from '../../modules/auth/send-authentication-code'
import {showError} from '../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../helpers/error-message-builder'

export function ForgotPassword() {
  const intl = useIntl()
  const history = useHistory()

  const {
    isSuccess,
    isError,
    error,
    data,
    mutate: enviarCodigo,
    isLoading,
  } = useSendAuthenticationCodeMutation()

  useEffect(() => {
    if (!isSuccess && !isError) return

    if (isSuccess) {
      history.push(`/auth/reset-password/${formik.values.txtEmail}`)
    } else if (isError) {
      showError(
        'Error',
        errorMessageBuilder(error, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isSuccess, isError])

  const initialValues = {
    txtEmail: '',
  }

  const forgotPasswordSchema = Yup.object().shape({
    txtEmail: Yup.string()
      .email(intl.formatMessage({id: 'FORMIK.EMAIL'}))
      .min(3, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '3'))
      .max(50, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '50'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setTimeout(() => {
        enviarCodigo({email: values.txtEmail})
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'AUTH.FORGOT.DESC'})}
        </div>
        {/* end::Link */}
      </div>
      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        </label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('txtEmail')}
          className={clsx('form-control form-control-lg form-control-solid')}
          type='email'
          autoComplete='off'
        />
        {formik.touched.txtEmail && formik.errors.txtEmail && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>
              {formik.errors.txtEmail}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <Link to='/auth/login'>
          <button
            type='button'
            name='btnCancelar'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON_CANCEL'})}
          </button>
        </Link>{' '}
        <button
          type='submit'
          name='btnSubmit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary fw-bolder me-4'
          disabled={isLoading}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON_SEND'})}
          </span>
          {isLoading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
