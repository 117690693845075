import {FC} from 'react'
import {EvalLaboratorioDesempenioMuestraDTO} from '../../models/DTOs/SCE/reportes/EvalLaboratorioDesempenioMuestraDTO'
import {useIntl} from 'react-intl'
import {Error404} from '../../components/errors/Error404'
import {useInitPdf} from '../../helpers/PdfHelper'
import TablaEvalLaboratorioDesempenioMuestra from '../../components/reportes/eval-laboratorio-desempenio-muestra/TablaEvalLaboratorioDesempenioMuestra'
import GraficoEnsayoPa from '../../components/reportes/graficos/GraficoEnsayoPa'
import GraficoEnsayoZscore from '../../components/reportes/graficos/GraficoEnsayoZscore'
import GraficoZscorePaLab from '../../components/reportes/graficos/GraficoZscorePaLab'

const ReporteEvalLabDesempenioMuestra: FC = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const reporte: EvalLaboratorioDesempenioMuestraDTO = dataDecoded.Reporte

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.REPORT'})}</h1>
      </div>
      <div className='p-2'>
        <div className='row'>
          <label className='col-2 form-label'>{intl.formatMessage({id: 'HEADER.PROGRAM'})}</label>
          <label className='col-8 form-label'>
            {`${intl.formatMessage({
              id: 'HEADER.CYCLE',
            })} / ${intl.formatMessage({
              id: 'HEADER.SURVEY',
            })} / ${intl.formatMessage({id: 'HEADER.SAMPLE'})}`}
          </label>
        </div>
        <div className='row mb-8'>
          <span className='col-2 fw-bolder'>{reporte.Programa}</span>
          <span className='col-8 fw-bolder'>{`${reporte.Ciclo} / ${reporte.Encuesta} | ${reporte.Muestra}`}</span>
        </div>
        <div className='mb-15'>
          <TablaEvalLaboratorioDesempenioMuestra reporte={reporte} />
        </div>
        <div className='mb-15'>
          <GraficoEnsayoZscore reporte={reporte} />
        </div>
        <div className='mb-15'>
          <GraficoEnsayoPa reporte={reporte} />
        </div>
        <div className='d-flex justify-content-center'>
          <div className='col-6'>
            <GraficoZscorePaLab reporte={reporte} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReporteEvalLabDesempenioMuestra
