import {FC} from 'react'
import {EvalProcMedidaDesempenioDTO} from '../../../models/DTOs/SCE/reportes/EvalProcMedidaDesempenioDTO'
import {useIntl} from 'react-intl'

interface Props {
  reporte: EvalProcMedidaDesempenioDTO
}

const TablaEvalProcMedidaDesempenio: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const hasChangedEDa = (actual: any, prev: any) => {
    if (!prev) return false
    const edaAct = actual.EspDesempAnalitico
    const edaPrev = prev.EspDesempAnalitico

    return edaAct.Id != edaPrev.Id
  }

  const getColor = (color: string) => {
    switch (color) {
      case 'verde':
        return '#1BBC9B'
      case 'verde-claro':
        return '#36D7B7'
      case 'amarillo':
        return '#F4D03F'
      case 'rojo':
        return '#e43a45'
      case 'rojo-claro':
        return '#E08283'
      default:
        return 'gray'
    }
  }

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder' style={{textAlign: 'center', verticalAlign: 'middle'}}>
            <th style={{width: `22%`}}>{`${intl.formatMessage({
              id: 'HEADER.CYCLE',
            })} / ${intl.formatMessage({
              id: 'HEADER.SURVEY',
            })} / ${intl.formatMessage({id: 'HEADER.SAMPLE'})}`}</th>
            <th style={{width: `6%`}}>%ETa</th>
            <th
              style={{width: `6%`}}
            >{`ETa (${reporte.ProcedimientoMedida.Mensurando.Unidades})`}</th>
            <th style={{width: `6%`}}>
              %ETa<sup>(1)</sup>
            </th>
            <th style={{width: `6%`}}>{intl.formatMessage({id: 'HEADER.INFORMED_VALUE'})}</th>
            <th style={{width: `6%`}}>
              X<sub>PT</sub>
            </th>
            <th style={{width: `6%`}}>%CV</th>
            <th style={{width: `6%`}}>
              σ<sub>pt</sub>
            </th>
            <th style={{width: `6%`}}>p</th>
            <th style={{width: `6%`}}>
              U
              <sub>
                X<sub>PT</sub>
              </sub>
            </th>
            <th style={{width: `6%`}}>Zscore</th>
            <th style={{width: `6%`}}>%D</th>
            <th style={{width: `6%`}}>D</th>
            <th style={{width: `6%`}}>
              P<sub>A</sub>
            </th>
          </tr>
        </thead>
        <tbody>
          {reporte.Resultados.map((resultado, index) => {
            return (
              <tr
                key={`tabla_eval_proc_medida_desempenio_${resultado.Id}`}
                style={{textAlign: 'right'}}
              >
                <td style={{textAlign: 'center'}}>
                  {`${resultado.Ciclo} / ${resultado.Encuesta} / ${resultado.Muestra}`}
                  <sup>{hasChangedEDa(resultado, reporte.Resultados[index - 1]) ? '(3)' : ''}</sup>
                </td>
                <td>
                  {resultado.EspDesempAnalitico.ETa.Percent?.Value.toFixed(
                    resultado.EspDesempAnalitico.ETa.Percent?.Precision
                  ) ?? '-'}
                </td>
                <td>
                  {resultado.EspDesempAnalitico.ETa.Concentration?.Value.toFixed(
                    resultado.EspDesempAnalitico.ETa.Concentration?.Precision
                  ) ?? '-'}
                </td>
                <td>{resultado.ETaAplicado.Value.toFixed(resultado.ETaAplicado.Precision)}</td>
                <td>
                  {resultado.ValorInformado.Value.toFixed(resultado.ValorInformado.Precision)}
                </td>
                <td>{resultado.Xpt.Value.toFixed(resultado.Xpt.Precision)}</td>
                <td>{resultado.CVg.Value.toFixed(resultado.CVg.Precision)}</td>
                <td>
                  {resultado.Sigmapt.Value.toFixed(resultado.Sigmapt.Precision)}
                  <sup>{resultado.IsCalcAutoSigmapt ? '(4)' : ''}</sup>
                </td>
                <td>{resultado.p}</td>
                <td
                  style={{color: '#FFF', backgroundColor: getColor(resultado.uxptEscalaColor.Name)}}
                >
                  {resultado.uxpt.Value.toFixed(resultado.uxpt.Precision)}
                </td>
                <td
                  style={{
                    color: '#FFF',
                    backgroundColor: getColor(resultado.ZscoreEscalaColor.Name),
                  }}
                >
                  {resultado.Zscore.Value.toFixed(resultado.Zscore.Precision)}
                  <sup>{resultado.IsZscorePrima ? '(2)' : ''}</sup>
                </td>
                <td
                  style={{
                    color: '#FFF',
                    backgroundColor: getColor(resultado.DesvPercentEscalaColor.Name),
                  }}
                >
                  {resultado.DesvPercent.Value.toFixed(resultado.DesvPercent.Precision)}
                </td>
                <td
                  style={{
                    color: '#FFF',
                    backgroundColor: getColor(resultado.DesvConcentrationEscalaColor.Name),
                  }}
                >
                  {resultado.DesvConcentration.Value.toFixed(resultado.DesvConcentration.Precision)}
                </td>
                <td
                  style={{color: '#FFF', backgroundColor: getColor(resultado.PaEscalaColor.Name)}}
                >
                  {resultado.Pa.Value.toFixed(resultado.Pa.Precision)}
                  <sup>{resultado.IsPaPrima ? '(2)' : ''}</sup>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div>
        <span>
          <b>(1):</b> ETa en porcentaje aplicado en la evaluación de la encuesta según el valor
          asignado.
        </span>
        <br />
        <span>
          <b>(2):</b> uXPT significativa.
        </span>
        <br />
        <span>
          <b>(3):</b> Actualización de la especificación de desempeño analítico.
        </span>
        <br />
        <span>
          <b>(4):</b> Se seleccionó el cálculo automático de σpt, motivo por el cual se asume una
          distribución normal de datos.
        </span>
      </div>
    </div>
  )
}

export default TablaEvalProcMedidaDesempenio
