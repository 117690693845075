import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './pages/auth/AuthInit'
import {Routes} from './routing/Routes'
import {QueryClient, QueryClientProvider} from 'react-query'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}})

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <QueryClientProvider client={queryClient}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Routes />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
