const tempStorageKey = '52-gmonitor-temp'

export const getAccessToken = () => {
  const obj = JSON.parse(localStorage.getItem('persist:56-asistencia-auth') || '')
  return obj === null ? null : obj.accessToken
}

export const setTempStorage = (value: string) => {
  localStorage.setItem(tempStorageKey, value)
}

export const getTempStorage = () => {
  return localStorage.getItem(tempStorageKey)
}
