import {FC, useEffect, useState} from 'react'
import more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {EvalLaboratorioDesempenioMuestraDTO} from '../../../models/DTOs/SCE/reportes/EvalLaboratorioDesempenioMuestraDTO'
more(Highcharts)

interface Props {
  reporte: EvalLaboratorioDesempenioMuestraDTO
}

const GraficoEnsayoZscore: FC<Props> = ({reporte}) => {
  const [options, setOptions] = useState<Highcharts.Options>()

  const GetPlotBand = (
    from: number,
    to: number,
    color: string
  ): Highcharts.YAxisPlotBandsOptions => {
    return {
      from: from,
      to: to,
      color: color,
    }
  }

  const GetPlotLine = (value: number): Highcharts.YAxisPlotLinesOptions => {
    const result: Highcharts.YAxisPlotLinesOptions = {
      value: value,
      width: 1,
      color: '#525E64',
      dashStyle: 'Dash',
      zIndex: 2,
    }

    return result
  }

  const GetScatterSerie = (
    reporte: EvalLaboratorioDesempenioMuestraDTO,
    fillColor: string,
    lineColor: string
  ): Highcharts.SeriesOptionsType => {
    return {
      type: 'scatter',
      data: reporte.Resultados.map((resultado) => {
        const valor = +resultado.Zscore.Value.toFixed(resultado.Zscore.Precision)

        return {
          y: valor > 4 ? 4 : valor < -4 ? -4 : valor,
          marker: {
            symbol: valor > 4 ? 'triangle' : valor < -4 ? 'triangle-down' : 'circle',
            radius: 4,
          },
          nombre: `${resultado.ProcedimientoMedida.Equipo.Descripcion} / ${resultado.ProcedimientoMedida.Equipo.Plataforma} | ${resultado.ProcedimientoMedida.Mensurando.Analito} / ${resultado.ProcedimientoMedida.Mensurando.Matriz} / ${resultado.ProcedimientoMedida.Mensurando.Unidades} | ${resultado.ProcedimientoMedida.Metodo.Reactivo} / ${resultado.ProcedimientoMedida.Metodo.PrincipioMedida}`,
          valor: valor,
        }
      }),
      marker: {
        enabled: true,
        symbol: 'circle',
        lineColor: lineColor || '#E86A04',
        fillColor: fillColor || '#FFF',
        lineWidth: 1,
        states: {
          hover: {
            enabled: false,
          },
          select: {
            enabled: false,
          },
        },
      },
    }
  }

  useEffect(() => {
    const info = {
      title: 'Ensayo vs Zscore',
      xAxisTitle: 'Ensayo',
      yAxisTitle: 'Zscore',
      yAxisMinValue: -4,
      yAxisMaxValue: 4,
      yAxisTickInterval: 1,
    }

    const series: Highcharts.SeriesOptionsType[] = []
    const plotBands: Highcharts.YAxisPlotBandsOptions[] = []
    const plotLinesY: Highcharts.YAxisPlotLinesOptions[] = []

    series.push(GetScatterSerie(reporte, '#FFF', '#000'))

    plotBands.push(GetPlotBand(-1, 1, '#26C281'))
    plotBands.push(GetPlotBand(1, 2, '#36D7B7'))
    plotBands.push(GetPlotBand(-1, -2, '#36D7B7'))
    plotBands.push(GetPlotBand(2, 3, '#F7CA18'))
    plotBands.push(GetPlotBand(-2, -3, '#F7CA18'))
    plotBands.push(GetPlotBand(3, 4, '#D91E18'))
    plotBands.push(GetPlotBand(-3, -4, '#D91E18'))

    plotLinesY.push(GetPlotLine(0))

    setOptions({
      title: {
        text: info.title,
      },
      xAxis: {
        type: 'category',
        title: {
          text: info.xAxisTitle,
        },
        categories: reporte.Resultados.map((_, index) => (index + 1).toString()),
      },
      yAxis: {
        title: {
          text: info.yAxisTitle,
        },
        min: info.yAxisMinValue,
        max: info.yAxisMaxValue,
        tickInterval: info.yAxisTickInterval,
        lineWidth: 1,
        plotBands: plotBands,
        gridLineWidth: 0,
        plotLines: plotLinesY,
      },
      plotOptions: {
        series: {
          animation: false,
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '[{point.nombre} ; {point.valor}]',
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: series,
    })
  }, [reporte])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoEnsayoZscore
