import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  email: string
}

const sendAuthenticationCode = async ({email}: Props) => {
  const {data} = await axios.post(`${ROUTES.USUARIOS}/reset-password`, null, {params: {email}})

  return data
}

const useSendAuthenticationCodeMutation = () => {
  return useMutation('useSendAuthenticationCodeMutation', (input: Props) =>
    sendAuthenticationCode(input)
  )
}

export {useSendAuthenticationCodeMutation, sendAuthenticationCode}
