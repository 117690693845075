import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  content?: string
  destination?: string
}

const createOTAToken = async ({content, destination}: Props) => {
  const {data} = await axios.post(`${ROUTES.AUTH}/ota`, {
    Content: content,
    Destination: destination,
  })

  return data
}

const useCreateOTATokenMutation = () => {
  return useMutation('useCreateOTATokenMutation', (input: Props) => createOTAToken(input))
}

export {useCreateOTATokenMutation, createOTAToken}
