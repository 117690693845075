import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {useResetPasswordMutation} from '../../modules/auth/reset-password'
import {showError} from '../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../helpers/error-message-builder'
import {AlertSuccess} from '../../components/UI/alerts/Alerts'

export function ResetPassword() {
  const intl = useIntl()
  const history = useHistory()

  const email = history.location.pathname.split('/')[3]

  const [showSuccess, setShowSuccess] = useState(false)

  const {
    isSuccess,
    isError,
    isLoading,
    error,
    data,
    mutate: resetearPassword,
  } = useResetPasswordMutation()

  useEffect(() => {
    if (!isSuccess && !isError) return

    if (isSuccess) {
      setShowSuccess(true)
      setTimeout(() => {
        history.push('/auth/login')
      }, 3000)
    } else if (isError) {
      showError(
        'Error',
        errorMessageBuilder(error, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isSuccess, isError])

  const initialValues = {
    txtPassword: '',
    txtCodigo: '',
  }

  const resetPasswordSchema = Yup.object().shape({
    txtPassword: Yup.string()
      .min(8, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '8'))
      .max(50, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '50'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
    txtCodigo: Yup.string().required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setTimeout(() => {
        resetearPassword({email: email, codigo: values.txtCodigo, password: values.txtPassword})
      }, 1000)
    },
  })
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.RESET.TITLE'})}</h1>
      </div>
      {showSuccess ? (
        <AlertSuccess>
          <span>{intl.formatMessage({id: 'AUTH.RESET.SUCCESS'})}</span>
        </AlertSuccess>
      ) : (
        <>
          <div className='text-center mb-10'>
            <div className='text-gray-400 fw-bold fs-4'>
              {intl.formatMessage({id: 'AUTH.RESET.DESC'})}
            </div>
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              {intl.formatMessage({id: 'HEADER.CODE'})}
            </label>
            <input
              type='text'
              placeholder={intl.formatMessage({id: 'HEADER.CODE'})}
              autoComplete='off'
              {...formik.getFieldProps('txtCodigo')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.txtCodigo && formik.errors.txtCodigo && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txtCodigo}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>
              {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            </label>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('txtPassword')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.txtPassword && formik.errors.txtPassword && (
              <div className='fv-plugins-message-container text-danger'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.txtPassword}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                name='btnCancelar'
              >
                {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON_CANCEL'})}
              </button>
            </Link>
            &nbsp;
            <button
              type='submit'
              name='btnSubmit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder ms-1'
              disabled={
                formik.isSubmitting ||
                !formik.isValid ||
                isLoading ||
                formik.getFieldProps('currentPassword').value === ''
              }
            >
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.RESET.BUTTON'})}
              </span>
              {isLoading && (
                <span className='indicator-progress'>
                  {intl.formatMessage({id: 'WAITING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </>
      )}
    </form>
  )
}
