import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  pdfData: any
}

const storePdfData = async ({pdfData}: Props) => {
  const {data} = await axios.post(`${ROUTES.PDF_OLD}/temp-data`, {
    Data: JSON.stringify(pdfData),
  })

  return data
}

const useStorePdfDataMutation = () => {
  return useMutation('useStorePdfDataMutation', (input: Props) => storePdfData(input))
}

export {storePdfData, useStorePdfDataMutation}
