import {RolUsuario} from '../models/DTOs/common/types'
import {UsersTableMock} from '../testing/tables/usuarios/usersTableMock'

const verifyAdminUser = (accessToken: any) => {
  if (accessToken === null) return [400]

  const user = UsersTableMock.getTable().find((x) => x.Auth?.AccessToken === accessToken)

  if (!user) return [400]

  if (user.Rol.Name !== RolUsuario.AdministradorLaboratorio) return [400]
}

export default verifyAdminUser
