import {FC} from 'react'
import {useIntl} from 'react-intl'
import {EvalLaboratorioDesempenioMuestraDTO} from '../../../models/DTOs/SCE/reportes/EvalLaboratorioDesempenioMuestraDTO'

interface Props {
  reporte: EvalLaboratorioDesempenioMuestraDTO
}

const TablaEvalLaboratorioDesempenioMuestra: FC<Props> = ({reporte}) => {
  const intl = useIntl()

  const getColor = (color: string) => {
    switch (color) {
      case 'verde':
        return '#1BBC9B'
      case 'verde-claro':
        return '#36D7B7'
      case 'amarillo':
        return '#F4D03F'
      case 'rojo':
        return '#e43a45'
      case 'rojo-claro':
        return '#E08283'
      default:
        return 'gray'
    }
  }

  return (
    <div className='table-responsive'>
      <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
        <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
          <tr className='fw-bolder' style={{textAlign: 'center', verticalAlign: 'middle'}}>
            <th style={{width: `5%`}}>#</th>
            <th style={{width: `25%`}}>
              {intl.formatMessage({id: 'HEADER.MEASUREMENT_PROCEDURE'})}
            </th>
            <th style={{width: `6%`}}>%ETa</th>
            <th style={{width: `6%`}}>%ESa</th>
            <th style={{width: `10%`}}>{intl.formatMessage({id: 'HEADER.INFORMED_VALUE'})}</th>
            <th style={{width: `6%`}}>
              X<sub>PT</sub>
            </th>
            <th style={{width: `6%`}}>%CV</th>
            <th style={{width: `6%`}}>
              σ<sub>pt</sub>
            </th>
            <th style={{width: `6%`}}>p</th>
            <th style={{width: `6%`}}>
              U
              <sub>
                X<sub>PT</sub>
              </sub>
            </th>
            <th style={{width: `6%`}}>Zscore</th>
            <th style={{width: `6%`}}>%D</th>
            <th style={{width: `6%`}}>
              P<sub>A</sub>
            </th>
          </tr>
        </thead>
        <tbody>
          {reporte.Resultados.map((resultado, index) => {
            return (
              <tr
                key={`tabla_eval_lab_desempenio_muestra_${resultado.Id}`}
                style={{textAlign: 'right'}}
              >
                <td style={{textAlign: 'center'}}>{index + 1}</td>
                <td style={{textAlign: 'center'}}>
                  {`${resultado.ProcedimientoMedida.Equipo.Descripcion} / ${resultado.ProcedimientoMedida.Equipo.Plataforma} | ${resultado.ProcedimientoMedida.Mensurando.Analito} / ${resultado.ProcedimientoMedida.Mensurando.Matriz} / ${resultado.ProcedimientoMedida.Mensurando.Unidades} | ${resultado.ProcedimientoMedida.Metodo.Reactivo} / ${resultado.ProcedimientoMedida.Metodo.PrincipioMedida}`}
                </td>
                <td>
                  {resultado.ETaAplicado?.Value.toFixed(resultado.ETaAplicado?.Precision) ?? '-'}
                </td>
                <td>
                  {resultado.ESa.Percent?.Value.toFixed(resultado.ESa.Percent?.Precision) ?? '-'}
                </td>
                <td>
                  {resultado.ValorInformado.Value.toFixed(resultado.ValorInformado.Precision)}
                </td>
                <td>{resultado.Xpt.Value.toFixed(resultado.Xpt.Precision)}</td>
                <td>{resultado.CVg.Value.toFixed(resultado.CVg.Precision)}</td>
                <td>
                  {resultado.Sigmapt.Value.toFixed(resultado.Sigmapt.Precision)}
                  <sup>{resultado.IsCalcAutoSigmapt ? '(4)' : ''}</sup>
                </td>
                <td>{resultado.p}</td>
                <td
                  style={{color: '#FFF', backgroundColor: getColor(resultado.uxptEscalaColor.Name)}}
                >
                  {resultado.uxpt.Value.toFixed(resultado.uxpt.Precision)}
                </td>
                <td
                  style={{
                    color: '#FFF',
                    backgroundColor: getColor(resultado.ZscoreEscalaColor.Name),
                  }}
                >
                  {resultado.Zscore.Value.toFixed(resultado.Zscore.Precision)}
                  <sup>{resultado.IsZscorePrima ? '(2)' : ''}</sup>
                </td>
                <td
                  style={{
                    color: '#FFF',
                    backgroundColor: getColor(resultado.DesvPercentEscalaColor.Name),
                  }}
                >
                  {resultado.DesvPercent.Value.toFixed(resultado.DesvPercent.Precision)}
                </td>
                <td
                  style={{color: '#FFF', backgroundColor: getColor(resultado.PaEscalaColor.Name)}}
                >
                  {resultado.Pa.Value.toFixed(resultado.Pa.Precision)}
                  <sup>{resultado.IsPaPrima ? '(2)' : ''}</sup>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div>
        <span>
          <b>(1):</b> ETa en porcentaje aplicado en la evaluación de la encuesta según el valor
          asignado.
        </span>
        <br />
        <span>
          <b>(2):</b> uXPT significativa.
        </span>
        <br />
        <span>
          <b>(3):</b> Actualización de la especificación de desempeño analítico.
        </span>
        <br />
        <span>
          <b>(4):</b> Se seleccionó el cálculo automático de σpt, motivo por el cual se asume una
          distribución normal de datos.
        </span>
      </div>
    </div>
  )
}

export default TablaEvalLaboratorioDesempenioMuestra
