import {randomNumber} from '../../../helpers/RandomString'
import {AnalitoModel} from '../../../models/AnalitoModel'

const KEY = 'AnalitosTableMock'

export class AnalitosTableMock {
  public static table: Array<AnalitoModel> = [
    {
      Id: 1,
      Descripcion: 'Sífilis',
      IsActivo: true,
      IsEditable: false,
      InstitucionId: 2,
    },
  ]

  private static _table: Array<AnalitoModel> = []

  private static read() {
    const storage = localStorage.getItem(KEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  private static save() {
    localStorage.setItem(KEY, JSON.stringify(this._table))
  }

  static createAnalito(descripcion: string, institucionId: number) {
    this.read()
    this._table.push({
      Id: randomNumber(5),
      Descripcion: descripcion,
      IsActivo: true,
      IsEditable: true,
      InstitucionId: institucionId,
    })
    this.save()
  }

  static updateAnalito(id: number, descripcion: string) {
    this.read()
    const analito = this.getTable().find((a) => a.Id === id)
    if (!analito) return

    analito.Descripcion = descripcion

    this.save()
  }

  static updateStatus(id: number) {
    this.read()
    const analito = this.getTable().find((a) => a.Id === id)
    if (!analito) return

    analito.IsActivo = !analito.IsActivo

    this.save()
  }
}
