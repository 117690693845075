import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

const getAplicaciones = async () => {
  const {data} = await axios.get(ROUTES.APLICACIONES_OLD)

  return data
}

const useGetAplicacionesQuery = () => {
  return useQuery('useGetAplicacionesQuery', () => getAplicaciones())
}

export {useGetAplicacionesQuery}
