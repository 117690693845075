import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../../models/UserModel'
import {SectorDTO} from '../../models/DTOs/sectores/SectorDTO'
import {jwtDecode} from 'jwt-decode'
import {getSectores} from '../../modules/sectores/get-sectores'
import {select} from 'redux-saga/effects'
import {RolUsuario} from '../../models/DTOs/common/types'
import {getUsuarioProfile} from '../../modules/usuarios/get-usuario-profile'
import {LaboratorioSummaryDTO} from '../../models/DTOs/laboratorios/LaboratorioSummaryDTO'
import {getLaboratorio} from '../../modules/laboratorio/get-laboratorio'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  SectorRequested: '[Request Sector] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UpdateToken: '[UpdateToken] Action',
  UpdateUser: '[UpdateUser] Action',
  SetSector: '[Set Sector] Action',
  SetLabAndSector: '[Set Lab And Sector] Action',
  SetIsLoading: '[Set Is Loading] Action',
  SetTempData: '[Set Temp Data] Action',
  ClearTempData: '[Clear Temp Data] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  sector: undefined,
  laboratorio: undefined,
  isLoading: false,
  tempData: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
  hash?: string
  sector?: SectorDTO
  laboratorio?: LaboratorioSummaryDTO
  isLoading?: boolean
  tempData?: any
}

export const reducer = persistReducer(
  {
    storage,
    key: process.env.REACT_APP_AUTH_KEY!,
    whitelist: [
      'user',
      'accessToken',
      'refreshToken',
      'sector',
      'laboratorio',
      'isLoading',
      'tempData',
    ],
  },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        const laboratorio = action.payload?.laboratorio
        const sector = action.payload?.sector

        return {
          accessToken,
          refreshToken,
          user: undefined,
          sector: sector,
          laboratorio: laboratorio,
          isLoading: true,
          tempData: undefined,
        }
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.SectorRequested: {
        return {...state}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        const sector = action.payload?.sector
        const laboratorio = action.payload?.laboratorio
        return {...state, user, sector, laboratorio, isLoading: false}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.UpdateToken: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {...state, accessToken, refreshToken}
      }

      case actionTypes.SetSector: {
        const sector = action.payload?.sector
        return {...state, sector}
      }

      case actionTypes.SetLabAndSector: {
        const sector = action.payload?.sector
        const laboratorio = action.payload?.laboratorio
        return {...state, sector, laboratorio}
      }

      case actionTypes.SetTempData: {
        const tempData = action.payload?.tempData
        return {...state, tempData}
      }

      case actionTypes.ClearTempData: {
        return {...state, tempData: undefined}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (
    accessToken: string,
    refreshToken: string,
    laboratorio?: LaboratorioSummaryDTO,
    sector?: SectorDTO
  ) => ({
    type: actionTypes.Login,
    payload: {accessToken, refreshToken, laboratorio, sector},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestSector: () => ({
    type: actionTypes.SectorRequested,
  }),
  fulfillUser: (user: UserModel, sector: SectorDTO, laboratorio: LaboratorioSummaryDTO) => ({
    type: actionTypes.UserLoaded,
    payload: {user, sector, laboratorio},
  }),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  updateToken: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.UpdateToken,
    payload: {accessToken, refreshToken},
  }),
  updateUser: (user: UserModel) => ({
    type: actionTypes.SetUser,
    payload: {user},
  }),
  setSector: (sector: SectorDTO) => ({type: actionTypes.SetSector, payload: {sector}}),
  setLabAndSector: (laboratorio: LaboratorioSummaryDTO, sector: SectorDTO) => ({
    type: actionTypes.SetLabAndSector,
    payload: {sector, laboratorio},
  }),
  setTempData: (tempData: any) => ({type: actionTypes.SetTempData, payload: {tempData}}),
  clearTempData: () => ({type: actionTypes.SetTempData}),
}

const getAuth = (state: any) => ({auth: state.auth})

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    const {auth} = yield select(getAuth)

    const accessToken = auth.accessToken
    const refreshToken = auth.refreshToken
    let laboratorio = auth.laboratorio
    let sector = auth.sector

    if (accessToken && refreshToken) {
      const decoded: any = jwtDecode(accessToken)

      const email = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
      const name = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
      const rol = parseInt(decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'])
      const id = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
      const institucionId = parseInt(decoded['lid'])

      let rolName = ''
      switch (rol) {
        case 5:
          rolName = RolUsuario.Administrador
          break
        case 6:
          rolName = RolUsuario.ResponsableLaboratorio
          break
        case 7:
          rolName = RolUsuario.AdministradorLaboratorio
          break
        default:
          break
      }

      const {data: profile} = yield getUsuarioProfile()

      const user: UserModel = {
        Id: +id,
        Auth: {
          AccessToken: accessToken,
          RefreshToken: refreshToken,
        },
        Email: email,
        Rol: {
          Id: rol,
          Name: rolName,
        },
        IsActivo: true,
        Nombre: name,
        InstitucionId: institucionId,
        IsResponsableFromGrupoLaboratorios: profile.IsResponsableFromGrupoLaboratorios,
      }

      if (!sector) {
        const {data: sectores} = yield getSectores()
        sector = sectores.find((sector: SectorDTO) => sector.Estado === 'enabled')
      }

      if (!laboratorio) {
        const {data: laboratorioDefault} = yield getLaboratorio()
        laboratorio = laboratorioDefault
      }

      yield put(
        actions.fulfillUser(user, sector, {
          Nombre: laboratorio.Nombre,
        })
      )
    }
  })
}
