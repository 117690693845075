import {UsuarioSectorDTO} from '../../../models/DTOs/common/UsuarioSectorDTO'

const USUARIOSSECTORES_KEY = 'UsuariosSectoresTableMock'

export class UsuariosSectoresTableMock {
  public static table: Array<UsuarioSectorDTO> = [
    {
      UsuarioId: 1,
      SectorId: 1,
    },
  ]

  private static _table: Array<UsuarioSectorDTO> = []

  private static save() {
    localStorage.setItem(USUARIOSSECTORES_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const usuariosSectoresStorage = localStorage.getItem(USUARIOSSECTORES_KEY)
    this._table = usuariosSectoresStorage !== null ? JSON.parse(usuariosSectoresStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearRelacionesMasivas(relaciones: UsuarioSectorDTO[]) {
    this.read()
    this._table = []
    this.save()

    for (const relacion of relaciones) {
      this.read()
      this._table.push({UsuarioId: relacion.UsuarioId, SectorId: relacion.SectorId})
      this.save()
    }
  }
}
