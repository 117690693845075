import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  email: string
  codigo: string
  password: string
}

const resetPassword = async ({codigo, email, password}: Props) => {
  const {data} = await axios.post(`${ROUTES.USUARIOS}/password`, {
    Email: email,
    Code: codigo,
    NewPassword: password,
  })

  return data
}

const useResetPasswordMutation = () => {
  return useMutation('useResetPasswordMutation', (input: Props) => resetPassword(input))
}

export {resetPassword, useResetPasswordMutation}
