const KEY = 'EDAFuentesTableMock'

export class EDAFuentesTableMock {
  public static table: Array<{
    Id: number
    Nombre: string
    AvailableConcentration: boolean
    AvailablePercent: boolean
  }> = [
    {
      Id: 1,
      Nombre: 'Variabilidad Biológica Mínima (SEQC)',
      AvailablePercent: true,
      AvailableConcentration: false,
    },
    {
      Id: 2,
      Nombre: 'Variabilidad Biológica Optima (SEQC)',
      AvailablePercent: true,
      AvailableConcentration: false,
    },
    {
      Id: 3,
      Nombre: 'Variabilidad Biológica Deseable (EFLM)',
      AvailablePercent: true,
      AvailableConcentration: false,
    },
    {
      Id: 4,
      Nombre: 'Variabilidad Biológica Mínima (EFLM)',
      AvailablePercent: true,
      AvailableConcentration: false,
    },
    {
      Id: 5,
      Nombre: 'Variabilidad Biológica Optima (EFLM)',
      AvailablePercent: true,
      AvailableConcentration: false,
    },
    {
      Id: 6,
      Nombre: 'CLIA 88',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
    {
      Id: 7,
      Nombre: 'CLIA 2024',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
    {
      Id: 8,
      Nombre: 'Estado del Arte',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
    {
      Id: 9,
      Nombre: 'RCPA',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
    {
      Id: 10,
      Nombre: 'Rilibak',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
    {
      Id: 11,
      Nombre: 'Consenso Español',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
    {
      Id: 12,
      Nombre: 'Otro',
      AvailablePercent: true,
      AvailableConcentration: true,
    },
  ]

  private static _table: Array<{
    Id: number
    Nombre: string
    AvailableConcentration: boolean
    AvailablePercent: boolean
  }> = []

  private static read() {
    const storage = localStorage.getItem(KEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }
}
