import {AplicacionModel} from '../../../models/AplicacionModel'

const APLICACIONES_KEY = 'AplicacionesTableMock'

export class AplicacionesTableMock {
  public static table: Array<AplicacionModel> = [
    {
      Id: 1,
      Nombre: 'CC',
      Descripcion: 'APPLICATION.DESCRIPTION.CC',
      Icono: '/media/logosG/Logo GM.png',
      Link: '#',
    },
    {
      Id: 2,
      Nombre: 'SCI',
      Descripcion: 'APPLICATION.DESCRIPTION.SCI',
      Icono: '/media/logosG/Logo GM.png',
      Link: '#',
    },
    {
      Id: 3,
      Nombre: 'SCE',
      Descripcion: 'APPLICATION.DESCRIPTION.SCE',
      Icono: '/media/logosG/Logo GM.png',
      Link: '#',
    },
    {
      Id: 4,
      Nombre: 'SEM',
      Descripcion: 'APPLICATION.DESCRIPTION.SEM',
      Icono: '/media/logosG/Logo GM.png',
      Link: '#',
    },
    {
      Id: 5,
      Nombre: 'Qi',
      Descripcion: 'APPLICATION.DESCRIPTION.QI',
      Icono: '/media/logosG/Logo GM.png',
      Link: '#',
    },
  ]

  private static _table: Array<AplicacionModel> = []

  private static read() {
    const aplicacionesStorage = localStorage.getItem(APLICACIONES_KEY)
    this._table = aplicacionesStorage !== null ? JSON.parse(aplicacionesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }
}
