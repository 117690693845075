import {EstadoType} from '../../../models/DTOs/common/types'
import {EDADTO} from '../../../models/DTOs/eda/EDADTO'
import {EDAModel} from '../../../models/EDAModel'
import {ProcedimientoMedidaModel} from '../../../models/ProcedimientoMedidaModel'
import {ProcedimientosMedidaTableMock} from '../procedimientos-de-medida/procedimientosMedidaTableMock'
import {EDAFuentesTableMock} from './fuentes/edaFuentesTableMock'

const KEY = 'EDATableMock'

export class EDATableMock {
  public static table: Array<EDAModel> = []

  private static _table: Array<EDAModel> = []

  private static read() {
    const storage = localStorage.getItem(KEY)
    this._table = storage !== null ? JSON.parse(storage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  private static save() {
    localStorage.setItem(KEY, JSON.stringify(this._table))
  }

  static createFromPM(sectorId: number, procedimiento: ProcedimientoMedidaModel) {
    this.read()

    this._table.push({
      SectorId: sectorId,
      Id: procedimiento.Id,
      Status: procedimiento.IsActivo ? EstadoType.Activo : EstadoType.Inactivo,
      Equipo: {
        Id: procedimiento.Equipo.Id,
        Descripcion: procedimiento.Equipo.Descripcion,
        Plataforma: procedimiento.Equipo.Plataforma.Descripcion,
      },
      Mensurando: procedimiento.Mensurando,
      Metodo: procedimiento.Metodo,
    })

    this.save()
  }

  static createEDAs(edas: EDADTO[]) {
    // this.read()
    // edas.forEach((eda) => {
    //   const procedimientoMedida = ProcedimientosMedidaTableMock.getTable().find(
    //     (p) => p.Id === eda.ProcedimientoMedidaId
    //   )
    //   const fuente = EDAFuentesTableMock.getTable().find((f) => f.Id === eda.FuenteId)
    //   if (!procedimientoMedida || !fuente) return
    //   const oldEDA = this._table.find((e) => e.Id === procedimientoMedida.Id)
    //   if (oldEDA) {
    //     oldEDA.EDA = {
    //       Fuente: fuente.Nombre,
    //       ETa: eda.ETa,
    //       ModeloESaEAa: eda.ModeloESaEAa,
    //       ESa: eda.ESa,
    //       EAa: eda.EAa,
    //     }
    //   } else {
    //     this._table.push({
    //       SectorId: procedimientoMedida.SectorId,
    //       Id: procedimientoMedida.Id,
    //       Status: procedimientoMedida.IsActivo ? EstadoType.Activo : EstadoType.Inactivo,
    //       Equipo: {
    //         Id: procedimientoMedida.Equipo.Id,
    //         Descripcion: procedimientoMedida.Equipo.Descripcion,
    //         Plataforma: procedimientoMedida.Equipo.Plataforma.Descripcion,
    //       },
    //       Mensurando: procedimientoMedida.Mensurando,
    //       Metodo: procedimientoMedida.Metodo,
    //       EDA: {
    //         Fuente: fuente.Nombre,
    //         ETa: eda.ETa,
    //         ModeloESaEAa: eda.ModeloESaEAa,
    //         ESa: eda.ESa,
    //         EAa: eda.EAa,
    //       },
    //     })
    //   }
    // })
    // this.save()
  }
}
