import {toAbsoluteUrl} from '../../../../app/helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img
        src={toAbsoluteUrl('/media/logosG/logo_gm.png')}
        height={200}
        width={400}
        alt='Start logo'
      />
      <span>Loading ...</span>
    </div>
  )
}
